import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import RepeatIcon from "@mui/icons-material/Repeat";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import CodeIcon from '@mui/icons-material/Code';
import "react-toastify/dist/ReactToastify.css";
import EditIcon from "@mui/icons-material/Edit";
import { Link, useParams, useHistory } from "react-router-dom";
import TodayIcon from "@mui/icons-material/Today";
import LinkIcon from "@mui/icons-material/Link";
import EditButton from "@mui/icons-material/Edit";
import DescriptionIcon from "@mui/icons-material/Description";
import CommentIcon from "@mui/icons-material/Comment";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import Visibility from "@mui/icons-material/Visibility";
import CheckIcon from "@mui/icons-material/Check";
import ScheduleIcon from "@mui/icons-material/Schedule";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import remove from "../Assets/images/icon/denied 1.gif";
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FilledInput from "@mui/material/FilledInput";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import ShimmerLoader from '../Components/ShimmerLoader';

import Dayjs from "dayjs";
import Schedule_Using_Date_Time_Frequency from "./Schedule_Using_Date_Time_Frequency";
import Output_History_Table from "./Output_History_Table";
import scheduling from "../Assets/images/icon/schedule_1.gif";
import EditNoteIcon from "@mui/icons-material/EditNote";
import CloseIcon from "@mui/icons-material/Close";

import axios from "axios";

import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { runScraperOnce } from "../Api/runScraperOnce";
import UpdateUrlModal from "../Components/UpdateUrlModal";
import useHomeStore from "../store/homeStore";
import HeaderMappingBox from "../Components/HeaderMappingBox";
import apiInstance from "../apiInterceptor";
import PythonCompiler from "../Components/PythonCompiler";
import CodeDisplayModal from "../Components/CodeDisplayModal";
import useCodeEditorStore from "../store/codeEditorStore";
import {
  cronToText,
  dataFormatter,
  dataFormatterOutputHistory,
  dateFormatter,
  fileName,
  generateCronExpression,
  handleApiError,
  jsonFilePath,
  transformString,
  isLatestObjectCompleted,
  dataFormatterSchedules,
  formatFrequencyData,
  formatDateString,
  getJobId,
} from "../utils";
import { LoadingBtn } from "../Components/LoadingBtn";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Editor } from "@monaco-editor/react";
import { set } from "date-fns";

const cronRegex =
  /^((\*|([0-5]?\d)(-([0-5]?\d))?)(\/([0-5]?\d)?)?)\s+((\*|([01]?[0-9]|2[0-3])(-([01]?[0-9]|2[0-3]))?)(\/([01]?[0-9]|2[0-3]))?)\s+((\*|([0-2]?[0-9]|3[0-1])(-([0-2]?[0-9]|3[0-1]))?)(\/([0-2]?[0-9]|3[0-1]))?)\s+((\*|([1-9]|1[0-2])(-([1-9]|1[0-2]))?)(\/([1-9]|1[0-2]))?)\s+((\*|[0-7]|([0-7](,[0-7])+))(\-([0-7]))?)$/;

const rowHeight = 24;
const headerHeight = 28;
const Frequency = [
  { label: "Select Frequency" },
  { label: "Hourly" },
  { label: "Daily" },
  { label: "Weekly" },
  { label: "Monthly" },
];

const FrequencyHourly = new Array(12).fill(null).map((_, i) => ({
  label: `Every ${i + 1} Hours`,
  hourInterval: i + 1,
}));

const Approved_Details_Page = ({ detailType, version }) => {
  const location = useLocation()
  const session = JSON.parse(localStorage.getItem("session")) || null;

  // check on initial load if session is present, if not redirect login, also stores current url in local storage
  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("session"))) {
      localStorage.setItem("pre-url",location.pathname)
      history.push("/signin");
    }
  }, []);
  const { urlid } = useParams();
  const history = useHistory();
  const pushVersion = version == "v2" ? "" : "v1/";

  // dropdown option
  // radio button
  const [selectedOption, setSelectedOption] = useState("b");
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);
  const [usnOpen, setUsnOpen] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [openRunOnce, setOpenRunOnce] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [checkVisible, setCheckVisible] = useState(true);
  const [csure, setSure] = useState(false);
  const [csure2, setSure2] = useState(false);
  const [open7,setOpen7] = useState(false);
  const [open11, setOpen11] = React.useState(false);
  const [open12, setOpen12] = React.useState(false);

  const [update, setUpdate] = useState({});
  const [temp, setTemp] = useState("");
  const [disableComment, setDisableComment] = useState("");
  const setHomeRowData = useHomeStore((state) => state.setRowData);

  const [details, setDetails] = useState([]);
  const [ScheduleData, setScheduleData] = useState([]);
  const [activitylogs, setActivitylogs] = useState([]);

  const {handleOpenEditor} = useCodeEditorStore()

  const [isSinglePage, setIsSinglePage] = useState(false);
  const [isDownloadingState, setDownloadingState] = useState(false);

  const [fileData, setFileData] = useState({
    data: {},
    id: null,
    type: null,
  });

  const [imageModal, setImageModal] = useState(false)
  const [currImage, setCurrImage] = useState("")

  const [expvalues] = useState({
    date: Dayjs(),
    time: Dayjs(),
    frequency: Frequency[0].label,
    hourInterval: FrequencyHourly[0].hourInterval,
    weeklyInterval: {
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false,
    },
  });

  const [isDownloadable, setIsDownloadable] = useState(false);

  const checkDownload = (data) => {
    if (data.file_download_flag == true) {
      setIsDownloadable(true);
    }
  };

  const [files, setFiles] = useState([]);

  const [schedule, enableScheduler] = useState(false);
  const [refresher, setRefresher] = useState(false);
  const [cronexp, setCronExp] = useState("");

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f7f7f7",
      borderRadius: "13px",
      color: "black",
      fontSize: "12px",
      maxWidth: 300,
      padding: "5px",
      boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px",
    },
  });

  const formatTooltipContent = (info) => {
    const points = info.split(";");
    return (
      <ul>
        {points.map((point, index) => (
          <li key={index}>{point.trim()}</li>
        ))}
      </ul>
    );
  };  

  // const [rowData_schedule, setRowData_schedule] = useState([{
  //   schedular_freq: "10",
  //   last_run: "345",
  //   next_run: "87",
  //   job_id: "2",
  //   full_desc_of_job: "schedule scraper",
  // }]);

  // row data
  const [rowData, setRowData] = useState();
  const [loading, setLoading] = useState(true);
  const [outputHistoryData, setOutputHistory] = useState();

  const [openal, setOpenal] = React.useState(false);
 
  const handleClickOpenal = () => {
    if(activitylogs.length == 0){
      toast.info("No activity logs found")
      return
    }
      setOpenal(true);
  };
  const handleCloseal = () => {
      setOpenal(false);
  };

  const [openUpdateUrl, setOpenUpdateUrl] = useState(false);
  // header row
  const [colDefs] = useState([
    {
      headerName: "",
      field: "comments",
      value: "1",
      minWidth: 50,
      flex: 1,
      cellStyle: { textAlign: "center" },
      cellRenderer: (params) => (
        <>
          <Box className="fx_pc">
            <Tooltip
              title={
                params.data.command === "scrape"
                  ? "Update Prompt"
                  : "Update Comment"
              }
            >
              <IconButton
                className="iconbutton_comment"
                onClick={() => {
                  setTemp(
                    params.data.command === "scrape"
                      ? params.data.prompts
                        ? params.data.prompts
                        : "?"
                      : params.data.comments
                      ? params.data.comments
                      : "-"
                  );
                  setUpdate({
                    step_id: `${params.data.seq}`,
                    url_id: urlid,
                    update_type:
                      params.data.command === "scrape" ? "prompt" : "comment",
                    [params.data.command === "scrape" ? "prompt" : "comment"]:
                      params.data.command === "scrape"
                        ? params.data.prompt
                          ? `${params.data.prompt}`
                          : "-"
                        : params.data.comment
                        ? `${params.data.comment}`
                        : "-",
                  });
                  handleClickOpen();
                }}
              >
                {params.data.command === "scrape" ? (
                  <EditIcon fontSize="small" className="comment_icon" />
                ) : params.data.command === "open" ? null : (
                  <CommentIcon fontSize="small" className="comment_icon" />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        </>
      ),
    },

    { headerName: "Seq", field: "seq", minWidth: 60, flex: 1 },
    { headerName: "Command", field: "command", minWidth: 100, flex: 1 },

    { headerName: "XPath", field: "xpath", minWidth: 130, flex: 3 },
    {
      headerName: "Prompts",
      field: "prompts",
      minWidth: 250,
      flex: 2,
    },
    { headerName: "Comments", field: "comments", minWidth: 250, flex: 2 },
    // {
    //   field: "",
    //   value: "1",
    //   minWidth: 55,
    //   flex: 1,
    //   headerClass: "center-header",
    //   cellStyle: {
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   },
    //   cellRenderer: (params) => (
    //     <>
    //     {(params.data.command != "open" && params.data.image != "-") &&  <IconButton
    //       onClick={() =>{
    //        setCurrImage(params.data.image)
    //        setImageModal(true)
    //       }
    //       }
    //     >
    //       <Image className="comment_icon" fontSize="small" />
    //     </IconButton>}
    //     </>
    //   ),
    // },
  ]);

  const [colDefs_schedule] = useState([
    // {
    //   headerName: "Info",
    //   field: "",
    //   minWidth: 55,
    //   flex: 1,
    //   sortable: true,
    //   cellRenderer: (params) => (
    //     <>
          
    //     </>
    //   ),
    //   cellStyle: {
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   },
    // },
    {
      headerName: "Actions",
      field: "",
      value: "1",
      minWidth: 60,
      flex: 1,
      // cellStyle: { textAlign: "center" },
      cellRenderer: (params) => (
        <>
            <Tooltip
            style={{marginRight:'3px'}}
            title={formatTooltipContent(params.data.info)}
            arrow
          >
            <IconButton
                className="iconbutton_comment"
              >
            <InfoOutlinedIcon fontSize="small" className="info_icon" />
            </IconButton>
          </Tooltip>
            <Tooltip style={{marginRight:'3px'}} title= "Update">
              <IconButton
                className="iconbutton_comment"
                onClick={() => {
                  if(params.data.scheduler_freq =="crone"){
                    setCronExp(params.data.chrone_expression)
                    
                  }else{
                    
                    setCronExp("")
                  }
                  
                  handleClickOpen12(params.data.job_id,params.data.freq_data,params.data.freq_type);
                }}
              >
                 <EditIcon fontSize="small" className="comment_icon" />
                 
              </IconButton>
            </Tooltip>
            {params.data.active && params.data.next && (  
              <Tooltip title="Disable">
                <IconButton
                  className="iconbutton_comment"
                  onClick={() => {
                    handleClickOpen11(params.data.job_id);
                  }}
                >
                  <RemoveCircleIcon fontSize="small" className="comment_icon" />
                </IconButton>
              </Tooltip>
            )}
        </>
      ),
      cellStyle: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginLeft:3,
            paddingLeft:3,
            paddingTop:0,
            marginTop:0,
          },
    },
    {
      headerName: "Status",
      field: "",
      value: "1",
      minWidth: 40,
      flex: 1,
      cellStyle: { textAlign: "center" },
      cellRenderer: (params) => (
        <>
          <Box id="Recent_status">
            <Tooltip title={params.data.active ? params.data.next ? "Active": "Completed" : "Disabled"}>
              {params.data.active && params.data.next && <FiberManualRecordIcon fontSize="small" className="Complete" />}
              {params.data.active && !params.data.next && <FiberManualRecordIcon fontSize="small" className="Scheduled" />}
              {!params.data.active && <FiberManualRecordIcon fontSize="small" className="Failed" />}
              
            </Tooltip>
          </Box>
        </>
      ),
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    { headerName: "Frequency", 
      field: "scheduler_freq", 
      minWidth: 90, 
      flex: 1,
      cellRenderer: (params)=> (
        <>
        <Tooltip title={params.data.scheduler_freq}>
          {params.data.scheduler_freq}
        </Tooltip>
        </>
      ),
    },

    // { headerName: "Last Run", field: "last_run", minWidth: 100, flex: 3 },
    {
      headerName: "Next Run",
      field: "next",
      minWidth: 90,
      flex: 2,
      cellRenderer: (params)=> (
        <>
        <Tooltip title={dateFormatter(params.data.next)}>
          {dateFormatter(params.data.next)}
        </Tooltip>
        </>
      ),
    },
    { headerName: "Description", 
      field: "desc", minWidth: 150, flex: 2, cellRenderer: (params)=> (
        <>
        <Tooltip title={params.data.desc}>
          {params.data.desc}
        </Tooltip>
        </>
      ), },
  ]);

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  /* modal box1 */
  const [currentJobId, setCurrentJobId] = useState("");
  const [currentFreqdetails, setCurrentFreqdetails] = useState({});
  const [currentFreqtype, setCurrentFreqtype] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpen12 = (job_id,freq_data,freq_type) => {
    console.log(job_id)
    if (freq_type == "crone") {
      setSelectedOption("a");
    } else {
      setSelectedOption("b");
    }
    setCurrentJobId(job_id)
    setCurrentFreqdetails(freq_data)
    setCurrentFreqtype(freq_type)
   setOpen12(true);
  };
  const handleClickOpen11 = (job_id) => {
    setCurrentJobId(job_id)
    setOpen11(true);
  };
  const handleClose7 = () => {
    setOpen7(false);
  };

  const handleClickOpenOutputHistory = async (
    value,
    details1,
    type,
    setIsDownloading
  ) => {
    await fetchJSONOutputHistory(value, details1, type, setIsDownloading);
    if (type.includes("pre")) setOpenDownload(true);
  };

  useEffect(() => {
    fetchMappingOptions();
    setIsSinglePage(details && details.scraper_type ? true : false);
    if (details && details.headers) {
      setKeyValueMap(details.headers);
      setKeyValueMapFinal(details.headers);
    }
  }, [details]);

  const [dataKeys, setDataKeys] = useState([]);
  const [mappingOptions, setMappingOptions] = useState([]);
  const [keyValueMap, setKeyValueMap] = useState({});
  const [keyValueMapFinal, setKeyValueMapFinal] = useState({});
  // const [isSinglePage, setIsSinglePage] = useState(false)

  const [open9, setOpen9] = React.useState(false);
  const [currPrompt, setCurrPrompt] = useState("");
  const [currPromptAdd, setCurrPromptAdd] = useState("");
  const [currPromptIndex, setCurrPromptIndex] = useState(null);
  const handleClickOpenResend = async (value, index) => {
    console.log(index);
    setRefresher(!refresher);
    setCurrPrompt(rowData[index - 1].prompts);
    setCurrPromptIndex(index - 1);
    await fetchJSONAPI(value);
    setOpen9(true);
  };
  function getValuesFromKeys(keysArray, obj) {
    return keysArray.map(key => obj[key]).filter(value => value !== undefined);
}

  const handlePromptUpdate = async () => {
    if (currPrompt == "") {
      toast.error("Prompt cannot be empty");
      return;
    }
    let message = "Prompt updated successfully";
    const endp = `${version}/update_prompt_comment_info`;
    console.log(dataKeys,"keys")
    // return
    const data = {
      url_id: urlid,
      step_id: `${currPromptIndex + 1}`,
      update_type: "prompt",
      prompt: currPrompt,
      existing_headers_keys: getValuesFromKeys(dataKeys, keyValueMapFinal),
    };
    if (currPromptAdd != "") {
      data.update_type = "additional_prompt";
      data.additional_prompt = currPromptAdd;
      message = "Additional prompt updated successfully";
      if (currPrompt != rowData[currPromptIndex].prompts) {
        data.update_type = "combined_additional_prompt";
        message = "Prompt & additional prompt updated successfully";
      }
    }
    try {
      const response = await apiInstance.patch(endp, data);

      if (response.statusText === "OK") {
        setCurrPromptAdd("");
        if (open9) {
          setOpen9(false);
          toast.success(`${message}`);
          setTimeout(() => {
            setRefresher(!refresher);
          }, 2000);
        } else {
          toast.success("Resent for preview");
        }
      }
    } catch (error) {
      setCurrPromptAdd("");
      console.error("Error sending PATCH request:", error);
      handleApiError(error)
    }
  };
  const handleDataKeyChange = (key, value) => {
    setKeyValueMap((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };
  const handleRemoveOption = (labelToRemove) => {
    setMappingOptions((prevOptions) =>
      prevOptions.filter((option) => option.label !== labelToRemove)
    );
  };
  const handleApplyMapping = async (data, value) => {
    if (value == "" || !value) {
      toast.info("Please enter a value first", {
        position: "bottom-right",
      });
      return;
    }
    const specialCharactersRegex = /[!@#\$%\^\&*\)\(+=._-]+/;
    if (specialCharactersRegex.test(value)) {
      toast.info("Special characters not allowed", {
        position: "bottom-right",
      });
      return;
    }
    if (
      !mappingOptions.some((option) => option.label === value.toUpperCase())
    ) {
      //add via api

      const url = `${version}/save_header_details/`;
      try {
        const response = await apiInstance.post(url, {
          project_id: details.project_code,
          header_name: value,
        });
        if (response.statusText === "OK") {
          fetchMappingOptions();
          toast.success("Added sucessfully, select the option again");
        }
      } catch (e) {
        handleApiError(e)
        console.log(e);
      }
    } else {
      toast.info("Entered value already exists", {
        position: "bottom-right",
      });
    }
  };
  function hasDuplicateValues(obj) {
    const valueOccurrences = {};
    for (const key in obj) {
      const value = obj[key];
      if (valueOccurrences[value]) {
        return true;
      } else {
        valueOccurrences[value] = true;
      }
    }

    return false;
  }
  const updateKeyValueMap = async () => {
    const updatedKeyValueMap = { ...keyValueMap };
    for (const key in updatedKeyValueMap) {
      if (
        updatedKeyValueMap.hasOwnProperty(key) &&
        updatedKeyValueMap[key] === ""
      ) {
        delete updatedKeyValueMap[key];
      }
    }
    if (hasDuplicateValues(updatedKeyValueMap)) {
      toast.error("Duplicates not allowed");
      return;
    }
    setKeyValueMap(updatedKeyValueMap);
    const url = `${version}/update_headers/`;
    try {
      const response = await apiInstance.post(url, {
        scraper_id: details.url_id,
        headers: updatedKeyValueMap,
      });

      if (response.statusText === "OK") {
        toast.success("Headers updated successfully");
        setTimeout(() => {
          window.location.reload()
        }, 2000);
        setKeyValueMapFinal(response.data.data.headers);
        setOpen2(false);
      }
    } catch (e) {
      handleApiError(e)
      console.log(e);
    }
  };

  const fetchMappingOptions = async () => {
    if (!details.project_code) return;
    const url = `${version}/get_headers/${details.project_code}`;
    try {
      const response = await apiInstance.get(url);

      if (response.statusText === "OK") {
        let tempArray = response.data.headers.map((obj) => {
          return { label: obj };
        }).sort((a, b) => a.label.localeCompare(b.label));
        setMappingOptions(tempArray);
      }
    } catch (e) {
      console.log(e);
    }
  };

  /* modal box2 */
  const handleClickOpen2 = async (value) => {
    await fetchJSONAPI(value);
    value.status === "approved"
      ? setCheckVisible(false)
      : setCheckVisible(true);
    setOpen2(true);
  };

  // remove modal
  const handleClickOpen3 = () => {
    setOpen3(true);
  };

  const handleClickOpen4 = () => {
    setOpen4(true);
  };
  const handleClickOpen5 = () => {
    setOpen5(true);
  };

  const handleClickOpenRunOnce = () => {
    setOpenRunOnce(true);
  };

  const handleClose = () => {
    let updation = { ...update };
    updation["prompt"] = temp;
    updateAPI(updation);

    setOpen(false);
    setTemp("");
    setSure(false);
    setUpdate({});
  };



  const handleClose3 = () => {
    setOpen3(false);
  };

  const handleCloseUsn = () => {
    let updation = { ...update };
    updation["scrapper_name"] = temp;
    updateAPI(updation);

    setUsnOpen(false);
    setTemp("");
    setSure(false);
    setUpdate({});
  };

  const handleClose4 = () => {
    setOpen4(false);
  };

  const handleCloseRunOnce = () => {
    setOpenRunOnce(false);
  };
  const handleClose5 = () => {
    setOpen5(false);
  };

  useEffect(() => {
    if (
      outputHistoryData &&
      outputHistoryData.some(
        (data) => data.status == "Scheduled" || data.status == "In_progress"
      )
    ) {
      setTimeout(() => {
        urlidAPI("approved");
        
      }, 300000);
    }
  }, [outputHistoryData]);

  const urlidAPI = async (status) => {
    const endp = `${version}/get_url_data?url_id=${urlid}&status_type=${status}`;
    try {
      const response = await apiInstance.get(endp);

      if (response.statusText === "OK") {
        setOutputHistory(
          dataFormatterOutputHistory(response.data.schedular_history)
        );
        setDetails(response.data);
        setScheduleData(dataFormatterSchedules(response.data.all_schedular_jobs_details));
        setActivitylogs(response.data.activity_logs);
        setRowData(dataFormatter(response.data.xpath_master));
        if (response.data.frequency_type == "crone") {
          setSelectedOption("a");
        } else {
          setSelectedOption("b");
        }
        response.data.transactions
          ? setFiles(jsonFilePath(response.data.transactions))
          : console.log("no transactions");
        checkDownload(response.data);
      }
    } catch (e) {
      setRowData([]);
      console.log(e);
    }finally{
      setLoading(false);
    }
  };

  const handleApprove = (id) => {
    approveAPI(id);
  };
  const approveAPI = async (tid) => {
    const endp = `${version}/approve-transaction/?transaction_id=${tid}&approved_by=${session.employeeCode}`;
    setFiles((prev) =>
      prev.map((i) => ({
        path: i.path,
        id: i.id,
        status: i.status,
        loader: i.id === tid ? true : false,
      }))
    );
try{
    const response = await apiInstance.post(endp, null);
    if (response.statusText === "OK") {
      setFiles((prev) =>
        prev.map((i) => ({
          path: i.path,
          id: i.id,
          status: i.id === tid ? "approved" : i.status,
          loader: false,
        }))
      );
      toast.success("Data approved");
      if (!isSinglePage) {
        setOpen2(false);
      }
      setCheckVisible(false);
      urlidAPI("approved")
      // setTimeout(() => {
      //   window.location.reload()
      // }, 1000);
    } else {
      toast.error("Something went wrong, please try again later");
    }
  }catch(e){
    handleApiError(e)
  }
  };

  useEffect(() => {
    console.log("refreshing due to refresh", refresher);
    urlidAPI("approved");
  }, [urlid, refresher]);

  function getAllUniqueKeys(obj, keys = new Set()) {
    if (typeof obj === "object" && obj !== null) {
      if (Array.isArray(obj)) {
        for (const item of obj) {
          getAllUniqueKeys(item, keys);
        }
      } else {
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            keys.add(key);
            getAllUniqueKeys(obj[key], keys);
          }
        }
      }
    }
    return Array.from(keys);
  }

  const fetchJSONAPI = async (value) => {
    const url = `${version}/preview_json_data`;

    try {
      if (version == "v2") {
        const response = await apiInstance.post(url, {
          prj_id: `${details.project_code}`,
          json_bucket_file_path: value.path,
        });

        if (response.statusText === "OK") {
          setDataKeys(getAllUniqueKeys(response.data.preview));
          setFileData({ data: response.data.preview, id: value.id });
        }
      } else {
        const response = await axios.get(
          process.env.REACT_APP_BUCKET_URL + value.path
        );
        if (response.statusText === "OK") {
          setFileData({ data: response.data, id: value.id });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };


  const handleDownload = (data, fileName, type) => {
    if (version == "v2") {
      if (!data) {
        setDownloadingState(false);
        return;
      }

      const link = document.createElement("a");
      link.href = data;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        setDownloadingState(false);
      }, 1000);
      toast.success("Download started successfully")
      document.body.removeChild(link);

      return;
    }

    const jsonData = JSON.stringify(data);
    let blob = new Blob([jsonData], { type: "application/json" });
    if (type == "logs") {
      blob = new Blob([data], { type: "text/plain" });
    }
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  const extractLogsFromUrl = async (url) => {
    if (!url) return "No logs found";
    const response = await axios.get(url);
    const data = await response.data;
    return data;
  };

  const fetchJSONOutputHistory = async (
    value,
    details1,
    type,
    setIsDownloading
  ) => {
    const url = `${version}/preview_json_data`;

    try {
      if (version == "v2") {
        const response = await apiInstance.post(url, {
          prj_id: `${details1.project_code}`,
          json_bucket_file_path: value.json?.replace(
            process.env.REACT_APP_BUCKET_URL,
            ""
          ),
          log_bucket_file_path: value.log?.replace(
            process.env.REACT_APP_BUCKET_URL,
            ""
          ),
        });
        let tempValue = value.json;
        if (type.includes("log")) {
          tempValue = value.log;
        }
        if (response.statusText === "OK") {
          setIsDownloading(false);
          if (!tempValue) {
            tempValue = "none";
          }
          const parts = tempValue
            ?.replace(process.env.REACT_APP_BUCKET_URL, "")
            .split("/");
          const fileNameDownload = parts[parts.length - 1];
          if (type.includes("json")) {
            setFileData({
              data: response.data.preview,
              id: tempValue.id,
              type: type,
              fileName: fileNameDownload,
              presigned_url: response.data.json_presigned_url,
            });
          } else {
            const logContent = await extractLogsFromUrl(
              response.data.log_presigned_url
            );
            setFileData({
              data: logContent,
              id: tempValue.id,
              type: type,
              fileName: fileNameDownload,
              presigned_url: response.data.log_presigned_url,
            });
          }
          setCheckVisible(false);
          if (!type.includes("pre")) {
            const parts = tempValue
              ?.replace(process.env.REACT_APP_BUCKET_URL, "")
              .split("/");

            const fileNameDownload = parts[parts.length - 1];

            if (type == "logs") {
              handleDownload(
                response.data.log_presigned_url,
                fileNameDownload,
                type
              );
            } else {
              handleDownload(
                response.data.csv_presigned_url
                  ? response.data.csv_presigned_url
                  : response.data.json_presigned_url,
                fileNameDownload,
                type
              );
            }
          }
        }
      } else {
        let tempValue = value.json;
        if (type.includes("log")) {
          tempValue = value.log;
        }

        const response = await axios.get(tempValue);
        if (response.statusText === "OK") {
          const parts = tempValue
            ?.replace(process.env.REACT_APP_BUCKET_URL, "")
            .split("/");
          const fileNameDownload = parts[parts.length - 1];
          setFileData({
            data: response.data,
            id: tempValue.id,
            type: type,
            fileName: fileNameDownload,
          });
          if (!type.includes("pre")) {
            if (type == "logs") {
              handleDownload(response.data, fileNameDownload, type);
            } else {
              handleDownload(response.data.preview, fileNameDownload, type);
            }
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const removeAPI_schedular = async (job_id = currentJobId, check = false) => {
    const endp = `${version}/stop-task/${job_id}?deleted_by=${session.employeeCode}`;
    
    try {
      const response = await apiInstance.delete(endp, null);
      if (response.statusText === "OK") {
        if(!check){
          toast.success("Successfully disabled Schedular scraper");
        setTimeout(() => {
          window.location.reload()
        }, 2000);
        }
      }
    } catch (error) {
      console.error("Error sending PATCH request:", error);
      handleApiError(error)
    }
  };

  const updateAPI = async (data) => {
    setUpdate((prev) => ({
      ...prev,
      prompt: temp,
    }));
    const endp = `${version}/update_prompt_comment_info`;
   
    const message =
      data.update_type === "prompt"
        ? "Prompt"
        : data.update_type === "comment"
        ? "Comment"
        : "Scraper Name";

    try {
      const response = await apiInstance.patch(endp, data);
      if (response.statusText === "OK") {
        toast.success(`${message} Succesfully updated`);
        setTimeout(() => {
          setRefresher(!refresher);
        }, 2000);
      } else {
        console.error("Error sending PATCH request:", error);
      }
    } catch (error) {
      handleApiError(error)
      console.error("Error sending PATCH request:", error);
    }
  };

  const reschedulerAPI = async (value) => {
    if (cronRegex.test(value)) {
      const urlcron = encodeURIComponent(value);

      const endp = `${version}/reschedule-task/?job_id=${currentJobId}&reschedule_by=${session.employeeCode}&new_cron_expression=${urlcron}`;
      if(ScheduleData.some(data => data.desc == cronToText(value))){
        toast.error("Existing crone expression cannot be used")
        return
      }
      try {
        const response = await apiInstance.post(endp, null);
        if (response.statusText === "OK") {
          setRefresher(!refresher);
          toast.success(`Succesfully re-scheduled scraper for ${urlid}`);

          setTimeout(() => {
            window.location.reload()
          }, 2000);
        }
      } catch (e) {
        console.log(e);
        handleApiError(e)
      }
    }
  };

  const schedulechroneAPI = async (value) => {
    if (cronRegex.test(value)) {
      const urlcron = encodeURIComponent(value);

      const endp = `${version}/schedule-task/?url_id=${urlid}&schedule_by=${session.employeeCode}&chrone_expression=${urlcron}&add_flag=${true}`;
      if(ScheduleData.some(data => data.desc == cronToText(value))){
        toast.error("Existing crone expression cannot be used")
        return
      }
      try {
        const response = await apiInstance.post(endp, null);
        if (response.statusText === "OK") {
          setRefresher(!refresher);
          toast.success(`Succesfully scheduled scraper for ${urlid}`);

          setTimeout(() => {
            window.location.reload()
          }, 2000);
        }
      } catch (e) {
        console.log(e);
        handleApiError(e)
      }
    }
  };

  function getMostRecentAddedOn(dataArray) {
    if (dataArray.length === 0) {
      return null;
    }

    let mostRecentDate = dataArray[0].added_on;
    for (let i = 1; i < dataArray.length; i++) {
      if (new Date(dataArray[i].added_on) > new Date(mostRecentDate)) {
        mostRecentDate = dataArray[i].added_on;
      }
    }
    return mostRecentDate;
  }

  const removeAPI = async () => {
    const endp = `${version}/delete_scrapper`;
    const data = {
      url_id: urlid,
      emp_code: session.employeeCode,
      disable_flag: true,
      comment: disableComment,
    };
    try {
      const response = await apiInstance.patch(endp, data);
      if (response.statusText === "OK") {
        toast.success("Successfully disabled scraper");
        setTimeout(() => {
          setHomeRowData([]);
          history.push(`/${pushVersion}`, { status: "approved" });
        }, 2000);
      }
    } catch (error) {
      console.error("Error sending PATCH request:", error);
      handleApiError(error)
    }
  };

  useEffect(() => {
    const allFilesApproved = files.every((file) => file.status === "approved");
    if (allFilesApproved && cronRegex.test(cronexp)) {
      enableScheduler(true);
    } else {
      enableScheduler(false);
    }
    if (
      files.some(
        (file) => file.status == "in_progress" || file.status == "pending"
      )
    ) {
      setTimeout(() => {
        urlidAPI("approved");
      }, 500000);
    }
  }, [files, cronexp]);

  useEffect(() => {
    if (details && details.frequency_type === "crone") {
      setCronExp(details.chrone_expression);
    }
  }, [details]);
  // header and row height
  const dataBoxWidth = details.scraper_type != "SINGLE_PAGE" ? 2.4 : 3;
  return (
    <>
      <Header />
      <Box id="page-container">
        <Container maxWidth>
          <Grid container spacing={1} mb={1} justifyContent="center">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="fx_sb_mob">
                <Box>
                  <Typography variant="h4" className="col1 fw5 ">
                    {detailType == "approved"
                      ? "Scheduled Scraper Details"
                      : "Today's Scheduled Scraper Details"}
                  </Typography>
                </Box>
                <Box className="fx_fs margin_10">
                  <Box mr={2}>
                    <Button
                      className="approve_btn"
                      onClick={() => {
                        history.push('/')
                      }}
                    >
                      Back
                    </Button>
                  </Box>
                  <Box mr={2}>
                    <Button className="approve_btn" onClick={handleClickOpen3}>
                      Disable
                    </Button>
                  </Box>
                  <Box>
                    <Button className="approve_btn" onClick={handleClickOpenal}>
                      Activity Logs
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container justifyContent="center" spacing={1}>
            <Grid item lg={dataBoxWidth} md={6} sm={6} xs={12}>
              <Link to="#">
                <Box className="cardbx fx_sb" p={1}>
                  <Box className="iconbx">
                    <IconButton
                      disableRipple
                      onClick={() => {
                        window.open(details.website_url, "_blank");
                      }}
                    >
                      <LinkIcon fontSize="small" className="col2 card_icon4" />
                    </IconButton>
                  </Box>
                  <Box>
                    <Box className="al_right" m={1}>
                      <Typography variant="h6" className=" col1 card_title one">
                        {urlid}
                      </Typography>
                      <Typography
                        variant="h5"
                        className=" gray card_subtitle one"
                        title={details.website_url ? details.website_url : "-"}
                      >
                        {details.website_url ? details.website_url : "-"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Link>
            </Grid>

            <Grid item lg={dataBoxWidth} md={6} sm={6} xs={12}>
              <Link to="#">
                <Box className="cardbx fx_sb" p={1}>
                  <Box className="iconbx">
                    <IconButton
                      disableRipple
                      onClick={() => {
                        setTemp(
                          details.scraper_name ? details.scraper_name : "-"
                        );
                        setSure(false);
                        setUpdate({
                          url_id: urlid,
                          update_type: "scrapper_name",
                          scrapper_name: details.scraper_name
                            ? details.scraper_name
                            : "-",
                        });
                        setUsnOpen(true);
                      }}
                    >
                      <EditButton
                        fontSize="large"
                        className="col2 card_icon4"
                      />
                    </IconButton>
                  </Box>
                  <Box>
                    <Box className="al_right" m={1}>
                      <Typography variant="h6" className=" col1 card_title one">
                        Scraper Name{" "}
                      </Typography>
                      <Typography
                        variant="h5"
                        className=" gray card_subtitle one"
                      >
                        {details.scraper_name ? details.scraper_name : "-"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Link>
            </Grid>
            <Grid item lg={dataBoxWidth} md={6} sm={6} xs={12}>
              <Link to="#">
                <Box className="cardbx fx_sb" p={1}>
                  <Box className="iconbx">
                    <IconButton disableRipple>
                      <DescriptionIcon
                        fontSize="large"
                        className="col2 card_icon4"
                      />
                    </IconButton>
                  </Box>
                  <Box>
                    <Box className="al_right" m={1}>
                      <Typography variant="h6" className=" col1 card_title one">
                        Scraper Type
                      </Typography>
                      <Typography
                        variant="h5"
                        className=" gray card_subtitle one"
                      >
                        
                        {details.urls_data &&
                        details.urls_data.length > 0 &&
                        details.scraper_type &&
                        details.scraper_type === "PAGINATION"
                          ? transformString(details.scraper_type +
                            " > " +
                            details.pagination_type +
                            " > " +
                            details.data_source_type +
                            " > " +
                            "MULTI_URL")
                          : details.scraper_type &&
                            details.scraper_type === "PAGINATION"
                          ? transformString(details.scraper_type +
                            " > " +
                            details.pagination_type +
                            " > " +
                            details.data_source_type)
                          : details.urls_data &&
                            details.urls_data.length > 0 &&
                            details.scraper_type &&
                            details.scraper_type === "NO_PAGINATION"
                          ? "No Pagination > Multi Url"
                          : details.file_download_flag
                          ? "File Download"
                          : details.scraper_type
                          ? transformString(details.scraper_type)
                          : ""}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Link>
            </Grid>
            {/* <Grid item lg={dataBoxWidth} md={6} sm={6} xs={12}>
              <Link to="#">
                <Box className="cardbx fx_sb" p={1}>
                  <Box className="iconbx">
                    <IconButton disableRipple>
                      <ScheduleIcon
                        fontSize="large"
                        className="col2 card_icon4"
                      />
                    </IconButton>
                  </Box>
                  <Box>
                    <Box className="al_right" m={1}>
                      <Typography variant="h6" className=" col1 card_title one">
                        Scheduler Frequency
                      </Typography>
                      <Tooltip
                        title={
                          details.chrone_expression
                            ? cronToText(details.chrone_expression)
                            : details.schedular_frequency
                            ? details.schedular_frequency
                            : "-"
                        }
                      >
                        <Typography
                          variant="h5"
                          className=" gray card_subtitle one"
                        >
                          {details.chrone_expression
                            ? cronToText(details.chrone_expression)
                            : details.schedular_frequency
                            ? details.schedular_frequency
                            : "-"}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              </Link>
            </Grid> */}
            <Grid item lg={dataBoxWidth} md={6} sm={6} xs={12}>
              <Link to="#">
                <Box className="cardbx fx_sb" p={1}>
                  <Box className="iconbx">
                    <IconButton disableRipple>
                      <TodayIcon fontSize="large" className="col2 card_icon4" />
                    </IconButton>
                  </Box>
                  <Box>
                    <Box className="al_right" m={1}>
                      <Typography variant="h6" className=" col1 card_title one">
                        Scheduled On
                      </Typography>
                      <Typography
                        variant="h5"
                        className=" gray card_subtitle one"
                      >
                        {details.schedular_history
                          ? dateFormatter(
                              getMostRecentAddedOn(details.schedular_history)
                            )
                          : "-"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Link>
            </Grid>

            {details.scraper_type != "SINGLE_PAGE" && (
              <Grid item lg={dataBoxWidth} md={6} sm={6} xs={12}>
                <Link to="#">
                  <Box className="cardbx fx_sb" p={1}>
                    <Box
                      onClick={() => {
                        details.urls_data && details.urls_data.length > 0
                          ? setOpenUpdateUrl(true)
                          : setOpenUpdateUrl(false);
                      }}
                      className="iconbx"
                    >
                      <IconButton disableRipple>
                        <EditNoteIcon
                          fontSize="large"
                          className="col2 card_icon4"
                        />
                      </IconButton>
                    </Box>
                    <Box>
                      <Box className="al_right" m={1}>
                        <Typography
                          variant="h6"
                          className=" col1 card_title one"
                        >
                          Number of URLs
                        </Typography>
                        <Typography
                          variant="h5"
                          className=" gray card_subtitle one"
                        >
                          {details.urls_data && details.urls_data.length > 0
                            ? details.urls_data.length
                            : "-"}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Link>
              </Grid>
            )}
          </Grid>

          <Grid container justifyContent="center" spacing={1}>
            <Grid item lg={7} md={7} sm={12} xs={12}>
              <Box className="whitebx" mt={1}>
                <Box className="fx_sb ai_end heading_bx">
                  <Box>
                    <Typography variant="h6" className=" col1 card_title ">
                      Scrapping Commands
                    </Typography>
                  </Box>
                </Box>
                <Box
                  className={"ag-theme-quartz  "}
                  style={{ width: "100%", height: "173px" }}
                >
                  <AgGridReact
                    rowData={rowData}
                    columnDefs={colDefs}
                    rowHeight={rowHeight}
                    headerHeight={headerHeight}
                    filter={true}
                    enableCellTextSelection={true}
                  />
                </Box>
              </Box>

              <Box className="whitebx" mt={1}>
                <Output_History_Table
                  response_data={outputHistoryData}
                  on_preview={handleClickOpenOutputHistory}
                  on_download={handleClickOpenOutputHistory}
                  details={details}
                  isDownloadable={isDownloadable}
                />
                {version == "v2" && (
                  <Box mt={2} className="al_right">
                    <Button
                      disabled={files.some((file) => file.status != "approved")}
                      onClick={() => handleClickOpenRunOnce()}
                      className="run_scraper_btn"
                    >
                      {" "}
                      Run Scraper{" "}
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>

            <Grid item lg={5} md={5} sm={12} xs={12}>
              <Grid container justifyContent="center" spacing={1}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box className="whitebx" mt={1}>
                    <Box className="heading_bx">
                      <Typography variant="h6" className=" col1 card_title ">
                        {isSinglePage
                          ? "Step 1: Data Preview, Approval, and Key Renaming (if necessary)"
                          : "Step 1: Data Preview and Approval"}
                      </Typography>
                    </Box>

                    <Box className="preview_mainbx">
                      {files.map((file, index) =>
                        file.id ? (
                          <Box className="preview_bx al_center " p={1}>
                            <Box>
                              <Typography
                                variant="h6"
                                className="one bl xstext  "
                              >
                                {file.status.startsWith("error")
                                  ? "Status: Failed"
                                  : "Status: " +
                                    file.status.charAt(0).toUpperCase() +
                                    file.status.slice(1).toLowerCase()}
                              </Typography>
                              <Typography
                                variant="h6"
                                className="card_preview_heading bl xstext"
                              >
                                {file.status === "approved" ||
                                file.status === "completed"
                                  ? fileName(file.path)
                                  : file.status.startsWith("error")
                                  ? "Change prompt and try again"
                                  : "Preview In Pending"}
                              </Typography>
                            </Box>
                            <Box mt={2}>
                              <Tooltip
                                title={
                                  file.path
                                    ? file.status === "approved"
                                      ? "Approved"
                                      : "Pending for Approve"
                                    : null
                                }
                                arrow
                                style={{
                                  display:
                                    file.status.startsWith("error") ||
                                    file.status == "pending"
                                      ? "none"
                                      : "inline-flex",
                                }}
                              >
                                {" "}
                                <IconButton
                                  style={{
                                    backgroundColor:
                                      file.status === "approved"
                                        ? "green"
                                        : "#1930ab",
                                  }}
                                  disabled={true}
                                >
                                  {file.loader ? (
                                    // <CircularProgress
                                    //   size={12}
                                    //   style={{ color: "white" }}
                                    // />
                                    <Box className="loadingbtn-container3">
                                      <Box className="dot3" />
                                    </Box>
                                  ) : (
                                    <CheckIcon
                                      className="approve_icon"
                                      style={{ color: "white" }}
                                    />
                                  )}
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                style={{
                                  display:
                                    file.status.startsWith("error") ||
                                    file.status == "pending"
                                      ? "none"
                                      : "inline-flex",
                                }}
                                title="Preview"
                                arrow
                              >
                                <IconButton
                                  onClick={() => handleClickOpen2(file)}
                                  disabled={file.path ? false : true}
                                  className="eye_btn"
                                >
                                  <Visibility
                                    fontSize="medium"
                                    className="wh approve_icon "
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Resend for preview" arrow>
                                {file.status == "pending" ? (
                                  <Box ml={4} className="loadingbtn-container2">
                                    <Box className="dot2" />
                                  </Box>
                                ) : (
                                  <IconButton
                                    disabled={
                                      file.status == "in_progress" ||
                                      file.status == "pending"
                                    }
                                    onClick={() => {
                                      handleClickOpenResend(
                                        file,
                                        parseInt(
                                          fileName(
                                            file.path
                                              ? file.path
                                              : file.html_path
                                          ).split("_")[2]
                                        )
                                      );
                                    }}
                                    className="repeat_btn"
                                  >
                                    {file.status == "in_progress" ||
                                    file.status == "pending" ? (
                                      <Box className="loadingbtn-container3">
                                        <Box className="dot3" />
                                      </Box>
                                    ) : (
                                      <RepeatIcon
                                        fontSize="medium"
                                        className="wh approve_icon "
                                      />
                                    )}
                                  </IconButton>
                                )}
                              </Tooltip>
                              {localStorage.getItem("techLead") =="true" &&  
                              <Tooltip
                                style={{
                                  display:
                                     (file.status !="approved" && file.status !="completed")
                                      ? "none"
                                      : "inline-flex",
                                

                                }}
                                title="Code"
                                arrow
                              >
                                <IconButton
                                    onClick={() => {handleOpenEditor(file, details)}}
                                    disabled={
                                      file.path
                                        ? (file.status != "approved" && file.status !="completed")
                                          ? true
                                          : false
                                        : true
                                    }
                                  className="eye_btn"
                                >
                                  <CodeIcon
                                    fontSize="medium"
                                    className="wh approve_icon "
                                  
                                  />
                                </IconButton>
                              </Tooltip>
}
                            </Box>
                          </Box>
                        ) : null
                      )}
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box className="whitebx2 schedule">
                  <Box className="heading_bx fx_sb_mob  ">
                  <Box>
                    <Typography variant="h6" className=" col1 card_title ">
                      Step 3: Scheduling of Scrapers
                    </Typography>
                  </Box>
                  <Box className="al_right">
                    <Button
                      className="run_scraper_btn"
                      onClick={() => {
                        setOpen7(true);
                      }}
                    >
                      Add
                    </Button>
                  </Box>
                </Box>
                <Box
                  className={"ag-theme-quartz  "}
                  style={{ width: "100%", height: "173px" }}
                >
                  {loading ? (
                    <ShimmerLoader />
                  ) : (
                  <AgGridReact
                    rowData={ScheduleData}
                    columnDefs={colDefs_schedule}
                    rowHeight={rowHeight}
                    headerHeight={headerHeight}
                    filter={true}
                    enableCellTextSelection={true}
                  />
                  )}
                </Box>
                    {/* <Box className="heading_bx fx_sb_mob  ">
                      <Box className="">
                        <Typography variant="h6" className=" col1 card_title ">
                          Step 3: Re-Scheduling of Scraper
                        </Typography>
                      </Box>

                      <Box>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={selectedOption}
                            onChange={handleRadioChange}
                          >
                            <FormControlLabel
                              value="b"
                              control={<Radio />}
                              label="Using Date, Time & Frequency"
                            />
                            <FormControlLabel
                              value="a"
                              control={<Radio />}
                              label="Using Cron Expression"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Box>

                    {selectedOption === "a" ? (
                      <Box className="cron_expression" my={2}>
                        <Grid container justifyContent="center" spacing={1}>
                          <Grid item lg={12} md={8} sm={12} xs={12}>
                            <FormControl fullWidth className="input_field">
                              <FilledInput
                                id="filled-adornment-password"
                                placeholder="Enter Cron Expression"
                                value={cronexp || ""}
                                onChange={(event) => {
                                  setCronExp(event.target.value);
                                }}
                                className="textfield_cron"
                              />
                            </FormControl>
                            <Box mt={1} className="al_left">
                              <Typography variant="h6" className="gray desc">
                                {" "}
                                Note: A cron expression is a string representing
                                a schedule in a time-based job scheduling system
                                (like Unix cron). It is typically made up of
                                five or six fields, separated by space, that
                                represent different units of time.
                              </Typography>

                              <Typography
                                variant="h6"
                                className="xstext"
                                mt={1}
                              >
                                {" "}
                                Example: A cron expression 0 12 * * Mon means
                                the task will run at 12:00 PM every Monday.
                                Learn more{" "}
                                <a
                                  href="https://crontab.guru/"
                                  className="a_underline"
                                  target="_blank"
                                >
                                  click here
                                </a>
                              </Typography>
                            </Box> */}
                          {/* </Grid>
                        </Grid>

                        <Box className="fx_c" mt={4}>
                          <Button
                            className="approve_btn"
                            disabled={!schedule}
                            onClick={() => {
                              handleClickOpen4();
                            }}
                          >
                            Re-schedule
                          </Button>
                        </Box>
                      </Box>
                    ) : (
                      <Schedule_Using_Date_Time_Frequency
                        urlid={urlid}
                        schedule_flag={schedule}
                        emp_code={session ? session.employeeCode : null}
                        job_id={details.job_id}
                        reschedule_flag={true}
                        freq_details={details.frequency_data}
                        freq_type={details.frequency_type}
                        version={version}
                        pageType="approved"
                        allowed={files.some(
                          (file) => file.status == "approved"
                        )}
                      />
                    )} */}
                  </Box> 
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* modal box1 */}

      <Box className="comment">
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title" className=" col1 card_title">
            {update.update_type === "prompt" ? "Prompts :" : "Comments :"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpen(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <HighlightOffIcon fontSize="small" className="gray" />
          </IconButton>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="whitebx text_area">
                <Box className="input_field">
                  <TextField
                    id="filled-basic"
                    className="filleddp padding0"
                    variant="filled"
                    multiline
                    name="message"
                    placeholder="Prompts information"
                    fullWidth
                    value={temp}
                    onChange={(event) => setTemp(event.target.value)}
                  />
                </Box>
              </Box>
            </DialogContentText>
          </DialogContent>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: "30px",
              margin: "10px 22px 10px 22px",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="Are you sure"
                  checked={csure}
                  onChange={() => {
                    setSure(!csure);
                  }}
                  size="10px"
                />
              }
              label={
                <span style={{ fontSize: "12px" }}>
                  Are You Sure You Want To update?
                </span>
              }
            />
            <Box>
              <Button
                onClick={() => {
                  handleClose();
                }}
                autoFocus
                className="approve_btn"
                disabled={!csure}
              >
                Update & Resend
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>

      {/* Re-schedule pop-up */}

      <Box className="comment">
        <Dialog
          open={open12}
          onClose={() => setOpen12(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth
        >
         <DialogContent sx={{ position: "relative" }}>
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpen12(false);
            }}
            sx={{
              position: "absolute",
              right: 3,
              top: 3,
            }}
          >
            <HighlightOffIcon fontSize="small" className="gray" />
          </IconButton>
          <Box sx={{ mt: 4 }} className="whitebx2 schedule">
                    <Box className="heading_bx fx_sb_mob  ">
                      <Box className="">
                        <Typography variant="h6" className=" col1 card_title ">
                          Step 3: Re-Scheduling of Scraper
                        </Typography>
                      </Box>

                      <Box>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={selectedOption}
                            onChange={handleRadioChange}
                          >
                            <FormControlLabel
                              value="b"
                              control={<Radio />}
                              label="Using Date, Time & Frequency"
                            />
                            <FormControlLabel
                              value="a"
                              control={<Radio />}
                              label="Using Cron Expression"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Box>

                    {selectedOption === "a" ? (
                      <Box className="cron_expression" my={2}>
                        <Grid container justifyContent="center" spacing={1}>
                          <Grid item lg={12} md={8} sm={12} xs={12}>
                            <FormControl fullWidth className="input_field">
                              <FilledInput
                                id="filled-adornment-password"
                                placeholder="Enter Cron Expression"
                                value={cronexp || ""}
                                onChange={(event) => {
                                  setCronExp(event.target.value);
                                }}
                                className="textfield_cron"
                              />
                            </FormControl>
                            <Box mt={1} className="al_left">
                              <Typography variant="h6" className="gray desc">
                                {" "}
                                Note: A cron expression is a string representing
                                a schedule in a time-based job scheduling system
                                (like Unix cron). It is typically made up of
                                five or six fields, separated by space, that
                                represent different units of time.
                              </Typography>

                              <Typography
                                variant="h6"
                                className="xstext"
                                mt={1}
                              >
                                {" "}
                                Example: A cron expression 0 12 * * Mon means
                                the task will run at 12:00 PM every Monday.
                                Learn more{" "}
                                <a
                                  href="https://crontab.guru/"
                                  className="a_underline"
                                  target="_blank"
                                >
                                  click here
                                </a>
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>

                        <Box className="fx_c" mt={3} >
                          <Button
                            className="approve_btn"
                            disabled={!schedule}
                            onClick={() => {
                              handleClickOpen5();
                            }}
                          >
                            Re-schedule
                          </Button>
                        </Box>
                      </Box>
                    ) : (
                      <Schedule_Using_Date_Time_Frequency
                        urlid={urlid}
                        schedule_flag={schedule}
                        emp_code={session ? session.employeeCode : null}
                        job_id={currentJobId}
                        reschedule_flag={true}
                        freq_details={currentFreqdetails}
                        freq_type={currentFreqtype}
                        all_desc={ScheduleData}
                        version={version}
                       
                        pageType="approved"
                        // allowed={ files.some((file) => file.status == "approved") &&
                        //   isLatestObjectCompleted(outputHistoryData)}
                        allowed={true}
                      />
                    )}
                  </Box>
          </DialogContent>
        </Dialog>
      </Box>

      {/* modal box2 */}

      <Box className="data preview">
        <Dialog
          open={open2}
          onClose={() => setOpen2(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={
            details && details.scraper_type && !isDownloadable ? "md" : "xs"
          }
          fullWidth
        >
          <DialogTitle id="alert-dialog-title2" className=" col1 card_title">
            {"Data Preview"}
          </DialogTitle>

          <IconButton
            aria-label="close"
            onClick={() => {
              setKeyValueMap(keyValueMapFinal);
              setOpen2(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <HighlightOffIcon fontSize="small" className="gray" />
          </IconButton>
          <Box my={1} className="data_preview">
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Grid
                  container
                  spacing={2}
                  alignItems="top"
                  justifyContent="center"
                >
                  <Grid
                    item
                    lg={isSinglePage && !isDownloadable ? 6 : 12}
                    md={isSinglePage && !isDownloadable ? 6 : 12}
                    sm={isSinglePage && !isDownloadable ? 6 : 12}
                    xs={12}
                  >
                    <Box className=" text_area ">
                      <Box className="input_field datapreview_bx">
                        <TextField
                          id="filled-basic"
                          className="filleddp padding0"
                          variant="filled"
                          multiline
                          minRows={7}
                          maxRows={7}
                          name="message"
                          fullWidth
                          value={JSON.stringify(fileData.data, null, 2)}
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>

                      {checkVisible ? (
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            height: "30px",
                            marginTop: "10px",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="Are you sure"
                                checked={csure}
                                onChange={() => {
                                  setSure(!csure);
                                }}
                                size="10px"
                              />
                            }
                            label={
                              <span style={{ fontSize: "12px" }}>
                                Are you sure you want to approve?
                              </span>
                            }
                          />
                          <Box>
                            <Button
                              onClick={() => handleApprove(fileData.id)}
                              autoFocus
                              disabled={!csure}
                              className="approve_btn"
                            >
                              Approve
                            </Button>
                          </Box>
                        </Box>
                      ) : null}
                    </Box>
                  </Grid>
                  {details && details.scraper_type && !isDownloadable && (
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Box className="datapreview_bx schedule">
                        {dataKeys.map((data, index) => {
                          const filteredOptions = mappingOptions.filter(
                            (option, index, self) => {
                              const isOptionIncluded = Object.values(keyValueMap).includes(option.label);
                              const isUniqueOption = index === self.findIndex((o) => o.label === option.label);
                              const isSpecialCase = option.label.startsWith(option.label[0]);
                              return (!isOptionIncluded || isSpecialCase) && isUniqueOption;
                            }
                          );
                          return (
                            <HeaderMappingBox
                              data={data}
                              filteredOptions={filteredOptions}
                              keyValueMap={keyValueMap}
                              handleApplyMapping={handleApplyMapping}
                              handleDataKeyChange={handleDataKeyChange}
                              keyValueMapFinal={keyValueMapFinal}
                              handleRemoveOption={handleRemoveOption}
                              mappingOptions={mappingOptions}
                            />
                          );
                        })}
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="Are you sure"
                              checked={csure2}
                              onChange={() => {
                                setSure2(!csure2);
                              }}
                              size="10px"
                            />
                          }
                          label={
                            <span style={{ fontSize: "12px" }}>
                              Are you sure you want to rename keys?
                            </span>
                          }
                        />
                        <Box>
                          <Button
                            onClick={() => updateKeyValueMap()}
                            autoFocus
                            disabled={!csure2}
                            className="approve_btn"
                          >
                            Update
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </DialogContentText>
            </DialogContent>
          </Box>
        </Dialog>
      </Box>

      {/* update scraper name modal */}
      <Box className="data preview">
        <Dialog
          open={usnOpen}
          onClose={() => setUsnOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title2" className=" col1 card_title">
            {"New Scraper Name :"}
          </DialogTitle>

          <IconButton
            aria-label="close"
            onClick={() => {
              setUsnOpen(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <HighlightOffIcon fontSize="small" className="gray" />
          </IconButton>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="whitebx text_area">
                <Box className="input_field">
                  <TextField
                    id="filled-basic"
                    className="filleddp padding0"
                    variant="filled"
                    multiline
                    minRows={2}
                    name="message"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={temp}
                    onChange={(event) => setTemp(event.target.value)}
                  />
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",

                    margin: "10px 0px 0px 0px",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="Are you sure"
                        checked={csure}
                        onChange={() => {
                          setSure(!csure);
                        }}
                        size="10px"
                      />
                    }
                    label={
                      <span style={{ fontSize: "12px" }}>
                        Are you sure you want to update changes?
                      </span>
                    }
                  />
                  <Box>
                    <Button
                      onClick={() => {
                        handleCloseUsn();
                      }}
                      autoFocus
                      className="approve_btn"
                      disabled={!csure}
                    >
                      Update
                    </Button>
                  </Box>
                </Box>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Box>
      {details.scraper_type != "SINGLE_PAGE" && (
        <UpdateUrlModal
          open={openUpdateUrl}
          setOpen={setOpenUpdateUrl}
          details={details}
          version={version}
          refresh={refresher}
          setRefresh={setRefresher}
        />
      )}
      {/* Modal Box for Disable */}

      <Box className="comment">
        <Dialog
          open={open3}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title" className=" col1 card_title">
            Comment
          </DialogTitle>

          <IconButton
            aria-label="close"
            onClick={() => {
              setOpen3(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <HighlightOffIcon fontSize="small" className="gray" />
          </IconButton>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="whitebx text_area">
                <Box className="input_field">
                  <TextField
                    id="filled-basic"
                    className="filleddp padding0"
                    variant="filled"
                    multiline
                    name="message"
                    placeholder="Comment"
                    fullWidth
                    value={disableComment}
                    onChange={(event) => setDisableComment(event.target.value)}
                  />
                </Box>
              </Box>
            </DialogContentText>
          </DialogContent>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: "30px",
              margin: "10px 22px 10px 22px",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="Are you sure"
                  checked={csure}
                  onChange={() => {
                    setSure(!csure);
                  }}
                  size="10px"
                />
              }
              label={
                <span style={{ fontSize: "12px" }}>
                  Are you sure you want to disable scraper?
                </span>
              }
            />
            <Box>
              <Button
                onClick={ () => {
                  ScheduleData.forEach(async (data) =>{
                    if(data.next){
                      await removeAPI_schedular(data.job_id, true)
                    }
                  })
                  removeAPI();
                  handleClose3();
                }}
                autoFocus
                className="approve_btn"
                disabled={!csure || disableComment === ""}
              >
                Disable
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>

      {/* modal box download */}
              
      <Box className="data preview">
        <Dialog
          open={openDownload}
          onClose={() => setOpenDownload(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title2" className=" col1 card_title">
            {"Data Preview"}
          </DialogTitle>

          <IconButton
            aria-label="close"
            onClick={() => {
              setOpenDownload(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <HighlightOffIcon fontSize="small" className="gray" />
          </IconButton>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="whitebx text_area">
                <Box className="input_field">
            
                <Editor
                        height="400px"
                        defaultLanguage="text"
                        value={
                          fileData.type && fileData.type.includes("logs")
                            ? fileData.data
                            : JSON.stringify(fileData.data, null, 2)
                        }
                        options={{minimap: {
                          enabled: false, // Disable the minimap
                      },wordWrap: 'on'}}
                      />
                  {/* <TextField
                    id="filled-basic"
                    className="filleddp padding0"
                    variant="filled"
                    multiline
                    minRows={2}
                    maxRows={8}
                    name="message"
                    fullWidth
                    value={
                      fileData.type && fileData.type.includes("logs")
                        ? fileData.data
                        : JSON.stringify(fileData.data, null, 2)
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  /> */}
                  {fileData.presigned_url != undefined && (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",

                        margin: "10px 0px 0px 0px",
                      }}
                    >
                      <Box>
                        {isDownloadingState ? (
                          <LoadingBtn />
                        ) : (
                          <Button
                            autoFocus
                            className="approve_btn"
                            onClick={() => {
                              setDownloadingState(true);
                              handleDownload(
                                fileData.presigned_url,
                                fileData.fileName,
                                fileData.type
                              );
                            }}
                          >
                            Download
                          </Button>
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Box>
      <Box className="data preview">
        <Dialog
          open={imageModal}
          onClose={() => {
            setImageModal(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              width: '1200px', // Set the desired width here
              height: '600px', // Set the desired height here (80% of the viewport height)
              maxWidth: 'none', // Ensures that maxWidth isn't limited by the `maxWidth` prop
            },
          }}
       
        >
          <DialogTitle id="alert-dialog-title2" className=" col1 card_title">
       
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => {
              setImageModal(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <HighlightOffIcon fontSize="small" className="gray" />
          </IconButton>
          <DialogContent >
            <DialogContentText id="alert-dialog-description">
              <Box className="  al_center" my={4}>
                <Box mb={2}>

                    <img
                      src={currImage}
                      alt="remove"
               
                      style={{
                        width: '1200px',    // Ensure the image takes up the full width
                        height: '600px',   // Ensure the image takes up the full height

                      }}
                    />
 
                </Box>

              </Box>
            </DialogContentText>
          </DialogContent>

        </Dialog>
      </Box>

      {/* Reschedule Modal */}
      <Box className="data preview">
        <Dialog
          open={open4}
          onClose={handleClose4}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="  al_center" my={4}>
                <Box mb={2}>
                  <Box>
                    <img
                      src={scheduling}
                      alt="Re-Schedule"
                      className="remove_icon_gif"
                    />
                  </Box>
                </Box>
                <Typography variant="h5" className="gray fw6">
                  Are you sure you want to schedule?
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <Box className="fx_c" mb={2}>
            <Box mr={1}>
              <Button
                onClick={() => {
                  schedulechroneAPI(cronexp);
                }}
                autoFocus
                className="approve_btn"
              >
                Schedule
              </Button>
            </Box>
            <Box>
              <Button onClick={handleClose4} autoFocus className="cancel_btn">
                Cancel
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>
      <CodeDisplayModal/>
      {/* Run Once Modal */}
      <Box className="data preview">
        <Dialog
          open={openRunOnce}
          onClose={handleCloseRunOnce}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="  al_center" my={4}>
                <Box mb={2}>
                  <Box>
                    <img
                      src={scheduling}
                      alt="Re-Schedule"
                      className="remove_icon_gif"
                    />
                  </Box>
                </Box>
                <Typography variant="h5" className="gray fw6">
                  Are you sure you want to run scraper once ?
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <Box className="fx_c" mb={2}>
            <Box mr={1}>
              <Button
                onClick={async () => {
                  await runScraperOnce(urlid, session.employeeCode, version);
                  setOpenRunOnce(false);
                  urlidAPI("approved");
                }}
                autoFocus
                className="approve_btn"
              >
                Run Once
              </Button>
            </Box>
            <Box>
              <Button
                onClick={handleCloseRunOnce}
                autoFocus
                className="cancel_btn"
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>

      {/* remove Scheduler alert dialog */}

      <Box className="data preview">
        <Dialog
          open={open11}
          onClose={() => {
            setOpen11(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="  al_center" my={4}>
                <Box mb={2}>
                  <Box>
                    <img
                      src={remove}
                      alt="remove"
                      className="remove_icon_gif"
                    />
                  </Box>
                </Box>
                <Typography variant="h5" className="gray fw6">
                  Are you sure you want to disable?
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <Box className="fx_c" mb={2}>
            <Box mr={1}>
              <Button
                onClick={() => {
                  removeAPI_schedular();
                  setOpen11(false);
                }}
                autoFocus
                className="approve_btn"
              >
                Ok
              </Button>
            </Box>
            <Box>
              <Button
                onClick={() => {
                  setOpen11(false);
                }}
                autoFocus
                className="cancel_btn"
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>

      {/* Scheduling of Scraper */}
      <Box className="data preview">
        <Dialog
          open={open7}
          onClose={handleClose7}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogContent sx={{ position: "relative" }}>
          <IconButton
            aria-label="close"
            onClick={() => {
              handleClose7();
            }}
            sx={{
              position: "absolute",
              right: 3,
              top: 3,
            }}
          >
            <HighlightOffIcon fontSize="small" className="gray" />
          </IconButton>
          <Box sx={{ mt: 4 }} className="whitebx2 schedule">
          <Box className="heading_bx fx_sb_mob" >
                      <Box className="">
                        <Typography variant="h6" className=" col1 card_title ">
                          Scheduling of Scraper
                        </Typography>
                      </Box>
                      <Box>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={selectedOption}
                            onChange={handleRadioChange}
                          >
                            <FormControlLabel
                              value="b"
                              control={<Radio />}
                              label="Using Date, Time & Frequency"
                            />
                            <FormControlLabel
                              value="a"
                              control={<Radio />}
                              label="Using Cron Expression"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
            </Box>
            {
              selectedOption === "a" ? (
                <Box className="cron_expression" my={2}>
                  <Grid container justifyContent="center" spacing={1}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <FormControl fullWidth className="input_field">
                        <FilledInput
                          className="textfield_cron"
                          id="filled-adornment-password"
                          placeholder="Enter Cron Expression"
                          onChange={(event) => {
                            setCronExp(event.target.value);
                          }}
                        />
                      </FormControl>
                      <Box mt={1} className="al_left">
                        <Typography variant="h6" className="gray desc">
                          {" "}
                          Note: A cron expression is a string
                          representing a schedule in a time-based job
                          scheduling system (like Unix cron). It is
                          typically made up of five or six fields,
                          separated by space, that represent different
                          units of time.
                        </Typography>

                        <Typography
                          variant="h6"
                          className="xstext"
                          mt={1}
                        >
                          {" "}
                          Example: A cron expression 0 12 * * Mon means
                          the task will run at 12:00 PM every Monday.
                          Learn more{" "}
                          <a
                            href="https://crontab.guru/"
                            className="a_underline"
                            target="_blank"
                          >
                            click here
                          </a>
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  <Box className="fx_c" mt={1}>
                    <Button
                      className="approve_btn"
                      disabled={!schedule || !cronRegex.test(cronexp)}
                      onClick={() => {
                        setOpen4(true);
                      }}
                    >
                      Schedule
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Schedule_Using_Date_Time_Frequency
                  urlid={urlid}
                  schedule_flag={schedule}
                  emp_code={session ? session.employeeCode : null}
                  reschedule_flag={true}
                  version={version}
                  add_flag={true}
                  all_desc={ScheduleData}
                  // freq_details={details.frequency_data}
                  //       freq_type={details.frequency_type}
                  pageType="approved2"
                  allowed={
                    files.some((file) => file.status == "approved") &&
                    isLatestObjectCompleted(outputHistoryData)
                  }
                  // allowed={true}
                />
              )

            }
          </Box>
          </DialogContent>
        </Dialog>
      </Box>

      {/* Reschedule Modal Date Time Frequency*/}
      <Box className="data preview">
        <Dialog
          open={open5}
          onClose={handleClose5}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="  al_center" my={4}>
                <Box mb={2}>
                  <Box>
                    <img
                      src={scheduling}
                      alt="Re-Schedule"
                      className="remove_icon_gif"
                    />
                  </Box>
                </Box>
                <Typography variant="h5" className="gray fw6">
                  Are you sure you want to re-schedule ?
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <Box className="fx_c" mb={2}>
            <Box mr={1}>
              <Button
                onClick={() => {
                  reschedulerAPI(cronexp);
                  setOpen5(false);
                }}
                autoFocus
                className="approve_btn"
              >
                Re Schedule
              </Button>
            </Box>
            <Box>
              <Button onClick={handleClose5} autoFocus className="cancel_btn">
                Cancel
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>
      {/* activity log */}

      <React.Fragment>
                <Dialog
                    onClose={handleCloseal}
                    aria-labelledby="customized-dialog-title"
                    open={openal} 
                    maxWidth="lg"
                    fullWidth
                >
                    <DialogTitle sx={{ m: 0, p: 1 }} id="customized-dialog-title">
                        <Typography variant='h4' className='col1 fw5' gutterBottom>Activity</Typography>
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                      
                        <CloseIcon fontSize="small" />
                    </IconButton>
                    <DialogContent dividers className="history_drawer" my={2}>
                   <Box my={2}>
                    
                   <table>
                        <tbody>
                          {activitylogs.map(activity =>{
                            return (<tr className='ai_top mb2'>
                              <td className='ai_top  '>
                                  <IconButton className='padding0'><FiberManualRecordIcon className='xtext_icon' /></IconButton> <Typography variant='h6' className='bl xtext lg2 fw4'>
                                      { <span>
                                        {(activity.action_by_emp_id == session.employeeCode ? "You" : activity.action_by)} {/* Underline action_by */}
                                        {" "}
                                        <strong>{activity.action_type=="RENAME_COLUMN" ?
                                        <>
                                       
                                         {"updated output preview keys" } 
                                         <br/>
                                         <br/>
                                        <span style={{marginTop:'3px'}}>{`Updated headers:  ${JSON.stringify(activity.action_changes ? activity.action_changes.new_changes : '-')}`}</span>
                                       </>
                                         :activity.action_msg}</strong> {/* Bold action_msg */}
                                        {"."}
                                        {activity.action_changes && activity.action_changes.new_changes ? (
                                          " " +
                                          (activity.action_type === 'RE-SCHEDULE' || activity.action_type === 'SCHEDULE'
                                            ? typeof activity.action_changes.new_changes === 'string'
                                              ? cronToText(activity.action_changes?.new_changes) + "." + getJobId(activity)
                    
                                              : formatFrequencyData(activity.action_changes?.new_changes) + getJobId(activity)
                                            
                                            : activity.action_type === 'RENAME_COLUMN'
                                            ? ""
                                            : activity.action_type === 'CODE_CHANGES'
                                            ? ""
                                            : "New " + activity.action_msg + ": " + activity.action_changes.new_changes + ".")
                                        ) : ""}
                                      </span>
                                      }</Typography>
                              </td>
                              <td><Typography variant='h6' ml={5} className='bl xtext lg fw4'>{activity.action_on.includes('T') ? dateFormatter(activity.action_on): formatDateString(activity.action_on)}</Typography></td>
                          </tr>)
                          })}
                            
                            

                        </tbody>
                    </table>
                   </Box>
                    </DialogContent>
                    {/* <DialogActions>
                        <Button className='sendbtn wh' onClick={handleCloseal}>
                            Save changes
                        </Button>
                    </DialogActions> */}
                </Dialog>
            </React.Fragment>
      {/* Resend For Preview */}
      <Box className="data preview">
        <Dialog
          open={open9}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title2" className=" col1 card_title">
            {"Data Preview"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpen9(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <HighlightOffIcon fontSize="small" className="gray" />
          </IconButton>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="whitebx text_area">
                <Box className="input_field">
                  <TextField
                    id="filled-basic"
                    className="filleddp padding0"
                    variant="filled"
                    multiline
                    minRows={4}
                    maxRows={4}
                    name="message"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={JSON.stringify(fileData.data, null, 2)}
                  />
                </Box>
                <Box my={1}>
                  <Typography variant="h6" className=" col1 card_title ">
                    Existing Prompt <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Box>
                <Box className="input_field">
                  <TextField
                    id="filled-basic"
                    className="filleddp padding0"
                    variant="filled"
                    name="message"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={currPrompt}
                    disabled={currPrompt =="Downloadable Links" || currPrompt =="LIST_ITEM" || currPrompt =="DATA_SOURCE" || currPrompt=="PAGINATION_SOURCE" || currPrompt =="Hyperlinks"}
                    onChange={(event) => setCurrPrompt(event.target.value)}
                  />
                </Box>
                <Box my={1}>
                  <Typography variant="h6" className=" col1 card_title ">
                    Additional Prompt (Optional)
                  </Typography>
                </Box>
                <Box className="input_field">
                  <TextField
                    id="filled-basic"
                    className="filleddp padding0"
                    variant="filled"
                    multiline
                    minRows={1}
                    name="message"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}

                    value={currPromptAdd}
                    onChange={(event) => setCurrPromptAdd(event.target.value)}
                  />
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "20px",
                    margin: "10px 0px 10px 0px",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="Are you sure"
                        checked={csure}
                        onChange={() => {
                          setSure(!csure);
                        }}
                        size="10px"
                      />
                    }
                    label={
                      <span style={{ fontSize: "12px" }}>
                        Are you sure you want to update?
                      </span>
                    }
                  />
                  <Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Button
                        onClick={() => {
                          handlePromptUpdate();
                        }}
                        autoFocus
                        className="approve_btn"
                        disabled={!csure}
                      >
                        Update & Resend
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Box>
      <ToastContainer
        position="top-center"
        style={{ fontSize: "16px", width: "600px", height: "200px" }}
      />
      <Footer />
    </>
  );
};

export default Approved_Details_Page;
