import React, { useState, useEffect } from 'react';
import useFilterStore from '../store/filterStateStore';

const CustomHeaderHome = (props) => {
    const [isChecked, setIsChecked] = useState(false);
    const {currentAction, setCurrentAction} = useFilterStore();


    const onCheckboxChange = () => {
        const newCheckedState = !isChecked;
        setIsChecked(newCheckedState);
        selectRowsOnCurrentPage(newCheckedState);
    };

    const selectRowsOnCurrentPage = (isSelected) => {
        const pageSize = props.api.paginationGetPageSize();
        const currentPage = props.api.paginationGetCurrentPage();
        const startRow = currentPage * pageSize;
        const endRow = Math.min(startRow + pageSize, props.api.getDisplayedRowCount());

        for (let i = startRow; i < endRow; i++) {
            const rowNode = props.api.getDisplayedRowAtIndex(i);
            const canSelectCheckbox = (
                currentAction === "Re-Schedule" || 
                currentAction === "Disable" || 
                (currentAction === "Rerun" && rowNode.data.Status === "Failed") || 
                (currentAction === "Download" && rowNode.data.Preview !== null)
            );

            // Only select the row if it meets the checkbox condition
            if (canSelectCheckbox) {
                rowNode.setSelected(isSelected);
            }
        }
    };

    useEffect(() => {
        const onPaginationChanged = () => {
            setIsChecked(false);
            selectRowsOnCurrentPage(false);
        };

        props.api.addEventListener('paginationChanged', onPaginationChanged);

        return () => {
            props.api.removeEventListener('paginationChanged', onPaginationChanged);
        };
    }, [props.api]);

    return (
        // <div >
            <input style={{position:"relative",right:'5px'}} type="checkbox" checked={isChecked} onChange={onCheckboxChange} />
        // </div>
    );
};

export default CustomHeaderHome;
