import axios from "axios";
import { format, parseISO, parse } from "date-fns";
import { toast } from "react-toastify";
let maxVersionGlobal = null

export const transformString = (input) => {
  return input
    .replace(/_/g, ' ') // Replace underscores with spaces
    .toLowerCase()      // Convert the entire string to lowercase
    .split(' ')         // Split the string into an array of words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(' ');         // Join the array back into a single string
}

export const handleApiError = (error) => {
  if (error.response) {
    if(error.response?.status === 500){
      toast.error("Internal server error, please try again later")
    }else{
      toast.error(error.response?.data?.message)
    } 
  } else if (error.request) {
    toast.error("Server not running, please try again later")
  } else {
    toast.error("Internal server error, please try again later")
  }
};

export async function fetchPythonFileContents(url) {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const fileContents = await response.text();
    return fileContents;
  } catch (error) {
    console.error('Error fetching Python file:', error);
    return null;
  }
}

export const jsonFilePath = (data) => {
    const maxVersion = Math.max(...data.map(i => i.scraper_version));
    maxVersionGlobal = maxVersion
    const filteredData = data.filter(i => i.scraper_version === maxVersion);
    return filteredData.map((i) => ({
      path: i.preview_json_file_path,
      id: i._id,
      status: i.progress_status,
      loader: false,
      html_path:i.html_code_file_path,
      python_path:i.progress_status === 'approved' ? i.python_script_file_path_prod : i.python_script_file_path
    }));
  };

  export const fileName = (file) => {
    const words = file.split("/");
    const filename = words[words.length - 1];
    return filename;
  };

  export const dateFormatter = (dateString) => {
    if (!dateString) return "-";
    const date = parseISO(dateString);
    const formattedDateTime = format(date, "MMM dd, yyyy hh:mm a");
    return formattedDateTime;
  };
  export const formatDateString=(dateString) => {
    if (!dateString) return "-";
  const date = parse(dateString, 'dd-MM-yyyy HH:mm:ss', new Date());
  return format(date, 'MMM dd, yyyy hh:mm a');
  }

  export const getJobId = (activity) =>{
    return activity.action_changes.job_id ?` Job Id: ${activity.action_changes.job_id} .` : ""
  }

  export const dataFormatter = (data) => {
    return data.map((d) => ({
      seq: d.command_seq_id,
      command: d.command,
      xpath: d.long_xpath,
      value: d.value,
      prompts: d.prompt_info,
      comments: d.step_comment,
      image:d.step_image_base64 ? d.step_image_base64 : "-"
    }));
  };

  export const dataFormatterSchedules = (data) => {
    return data.map((d) => ({
      next: d.next_run_time,
      scheduled_by: d.scheduled_by_name,
      scheduler_freq: d.chrone_expression
      ? d.frequency_type
      : d.frequency_data
      ? d.frequency_data.frequency
      : "-",
      job_id: d.job_id,
      freq_type: d.frequency_type,
      freq_data:d.frequency_data,
      scheduled_on: d.added_on,
      updated_on: d.updated_on,
      active: d.active_flag,
      desc: d.chrone_expression
      ? cronToText(d.chrone_expression)
      : d.frequency_data
      ? formatFrequencyData(d.frequency_data)
      : "-",
      chrone_expression: d.chrone_expression,
      disabled_by_name: d.disabled_by_name,
      info: `Scheduled By: ${d.scheduled_by_name ? d.scheduled_by_name : "-"}; 
      Scheduled on: ${dateFormatter(d.added_on)}; 
      Updated on: ${dateFormatter(d.updated_on)}${!d.active_flag && d.disabled_by_name ? `; Disabled by: ${d.disabled_by_name}` : ""}
      ${!d.active_flag && d.deleted_on ? `; Disabled on: ${dateFormatter(d.deleted_on)}` : ""} ${d.job_id && `; Job id: ${d.job_id}` }`
,
    }));
  };

  export function formatTimeString(timeString) {
    const [hours, minutes, seconds] = timeString.split(":");
  
    const parsedHours = parseInt(hours, 10);
    const parsedMinutes = parseInt(minutes, 10);
    const parsedSeconds = parseFloat(seconds).toFixed(3);
  
    let formattedTime;
    if (parsedHours === 0 && parsedMinutes === 0) {
      // Return only seconds as a decimal if both hours and minutes are zero
      formattedTime = `${parsedSeconds}s`;
    } else if (parsedHours === 0) {
      // Return seconds as an integer if minutes are non-zero
      formattedTime = `${parsedMinutes}m ${Math.round(parsedSeconds)}s`;
    } else {
      // Return hours, minutes, and seconds as integers
      formattedTime = `${parsedHours}h ${parsedMinutes}m ${Math.round(parsedSeconds)}s`;
    }
  
    return formattedTime;
  }


  export const dataFormatterOutputHistory = (data) => {
    data.forEach(record => {
      if (!record.scraper_version) {
        record.scraper_version = 1;
      }
    });
    

    const uniqueMap = new Map();
    data.forEach((record) => {
      const key = `${dateFormatter(record.added_on)}`;
      if (!uniqueMap.has(key)) {
        uniqueMap.set(key, record);
      }
    });
    const distinctData = Array.from(uniqueMap.values());
    distinctData.sort((a, b) => b.scraper_version - a.scraper_version);
    const finalData= distinctData.map((d) => ({
      scheduletime: d.updated_on
        ? dateFormatter(d.updated_on)
        : dateFormatter(d.added_on),
      completed_on: d.completed_on ? dateFormatter(d.completed_on) : "-",
      status:
        d.progress_status.charAt(0).toUpperCase() +
        d.progress_status.slice(1).toLowerCase(),
      download: d.output_file_path
        ? process.env.REACT_APP_BUCKET_URL + d.output_file_path
        : null,
      preview: d.output_file_path
        ? process.env.REACT_APP_BUCKET_URL + d.output_file_path
        : null,
        preview_log:d.error_log_file_path 
        ? process.env.REACT_APP_BUCKET_URL + d.error_log_file_path 
        : d.log_file_path ? process.env.REACT_APP_BUCKET_URL + d.log_file_path : null,
        preview_csv:d.csv_output_log_file_path 
        ? process.env.REACT_APP_BUCKET_URL + d.csv_output_log_file_path 
        : null,
        duration:d.time_taken_to_complete ? formatTimeString(d.time_taken_to_complete) : '-',
        error_msg: d.error_msg ? d.error_msg : '-',
        scraper_version: d.scraper_version ? `V${d.scraper_version}` : '-',
        version: d.scraper_version?d.scraper_version:0,
    })).sort((a, b) => {
      if (b.version !== a.version) {
        return b.version - a.version;
      } else {
        return new Date(b.scheduletime) - new Date(a.scheduletime);
      }
  })
    return finalData
  };

  export const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  export const generateCronExpression = (data) => {
    const month = data.date ? data.date["$M"] + 1 : "*";
    const day = data.date ? data.date["$D"] : "*";
    const hour = data.time ? data.time["$H"] : "*";
    const minute = data.time ? data.time["$m"] : "*";
    const hourly = data.hourInterval
      ? data.hourInterval === 1
        ? "*"
        : `*/${data.hourInterval}`
      : "*";
    const weekly = Object.keys(data.weeklyInterval).filter(
      (day) => data.weeklyInterval[day]
    );
  
    const dayMap = {
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
      Sunday: 7,
    };
    const days =
      weekly.map((day) => dayMap[day]).join(",") === "1,2,3,4,5,6,7" ||
      weekly.map((day) => dayMap[day]).join(",") === ""
        ? "1"
        : weekly.map((day) => dayMap[day]).join(",");
  
    if (data.frequency !== "Select Frequency") {
      if (data.frequency === "Hourly") {
        return `${minute} ${hourly} ${day} * *`;
      } else if (data.frequency === "Daily") {
        return `${minute} ${hour} * * *`;
      } else if (data.frequency === "Monthly") {
        return `${minute} ${hour} ${day} * *`;
      } else if (data.frequency === "Weekly") {
        return `${minute} ${hour} * * ${days}`;
      }
    } else {
      return `${minute} ${hour} ${day} ${month} *`;
    }
  };

  export function isLatestObjectCompleted(objects) {
    const sortedObjects = objects.sort(
      (a, b) => new Date(b.scheduletime) - new Date(a.scheduletime)
    );

    const latestObject = sortedObjects[0];
    return (
      objects.length > 0 && latestObject && latestObject.status === "Completed"
    );
  }

  export const formatFrequencyData = (frequency_data) => {
    const daysOfWeekMap = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday","Sunday"];
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    
    // Extracting values from the data
    const { interval, days_of_week, day, months, start_date, start_time, frequency } = frequency_data;

    // Handling day and month formatting
    const dayString = day.length > 0 ? day.map(d => d === -1 ? "last" : d).join(", ") : "";
    const monthsString = months.length > 0 ? months.map(month => monthNames[month - 1]).join(", ") : "";

    // Converting time to 12-hour format
    const [hour, minute, second] = start_time.split(":").map(Number);
    const period = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
    const formattedTime = `${formattedHour}:${minute.toString().padStart(2, '0')} ${period}`;
    
    // Constructing the final string based on the frequency type
    let resultString = "";

    if (frequency === "Weekly") {
        const daysOfWeekString = days_of_week.map(day => daysOfWeekMap[day]).join(", ");
        resultString = `Occurs every ${interval} weeks, day of ${daysOfWeekString}. Starting from ${start_date} at ${formattedTime}.`;
    } else if (frequency === "Monthly") {
        resultString = `Occurs monthly on the ${dayString} days of ${monthsString}. Starting from ${start_date} at ${formattedTime}.`;
    } else if (frequency === "Daily") {
        resultString = `Occurs every ${interval} days. Starting from ${start_date} at ${formattedTime}.`;
    } else if (frequency === "Hourly") {
        resultString = `Occurs every ${interval} hours. Starting from ${start_date} at ${formattedTime}.`;
    } else if (frequency === "One Time") {
        resultString = `Occurs one time. Starting from ${start_date} at ${formattedTime}.`;
    }

    return resultString;
  };

  export const cronToText = (expression) => {
    const parts = expression.split(" ");
    if (parts.length < 5 || parts.length > 6) {
      return "Invalid cron expression";
    }
  
    const [minute, hour, dayOfMonth, month, dayOfWeek] = parts;
  
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
  
    const parsedMinute =
      minute === "*" ? "every minute" : `at ${minute} minutes past the hour`;
    const parsedHour =
      hour === "*"
        ? "every hour"
        : `at ${hour === "0" ? 12 : hour % 12} ${hour >= 12 ? "PM" : "AM"}`;
    const parsedDayOfMonth =
      dayOfMonth === "*" ? "every day" : `on day ${dayOfMonth}`;
    const parsedMonth =
      month === "*" ? "every month" : `in ${months[parseInt(month) - 1]}`;
    const parsedDayOfWeek =
      dayOfWeek === "*" ? "" : `on ${days[parseInt(dayOfWeek)]}`;
  
    return `Run ${parsedMinute}, ${parsedHour}, ${parsedDayOfMonth}, ${parsedMonth} ${parsedDayOfWeek}`
      .replace(/\s\s+/g, " ")
      .trim();
  
  };

export  const extractLogsFromUrl = async (url) => {
  if (!url) return "No logs found";
  const response = await axios.get(url);
  const data = await response.data;

  return data;
};

export function getDayOfWeek(dayOfWeek) {
  // Replace this with an array or lookup table for day of week names
  const dayOfWeekNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const dayNumber = parseInt(dayOfWeek, 10);
  if (dayNumber >= 0 && dayNumber <= 7) {
    return dayOfWeekNames[dayNumber - 1];
  } else {
    return "Invalid day of week";
  }
}

function getMonthName(month) {
  // Replace this with an array or lookup table for month names
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthNumber = parseInt(month, 10);
  if (monthNumber >= 1 && monthNumber <= 12) {
    return monthNames[monthNumber - 1];
  } else {
    return "Invalid month";
  }
}


export function getCurrentDateTime() {
  // Create a new Date object with the current date and time
  const now = new Date();

  // Get individual components of the date and time
  const year = now.getFullYear();     // 4-digit year (YYYY)
  const month = now.getMonth() + 1;   // Month (1-12, add 1 because January is 0)
  const day = now.getDate();          // Day of the month (1-31)
  const hours = now.getHours();       // Hours (0-23)
  const minutes = now.getMinutes();   // Minutes (0-59)
  const seconds = now.getSeconds();   // Seconds (0-59)

  // Return the formatted date and time as a string
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}


export function updatePythonCode(pythonCode, htmlCode) {
  // Step 1: Replace 'lxml' with 'html.parser'
  let updatedCode = pythonCode.replace(/lxml/g, 'html.parser');

  // Step 2: Check if the `html_content` variable exists
  const htmlContentPattern = /^.*html_content.*$/gm;

  if(htmlContentPattern.test(updatedCode)){
    updatedCode = removeContentAfterHtmlContent(updatedCode)
  }


    // Step 3: Find the `import json` line and insert `html_content` right after it
    // const importJsonPattern = /(\bimport\s+json\b.*)/;
    // if (importJsonPattern.test(updatedCode)) {

    //   updatedCode = updatedCode.replace(importJsonPattern, `$1\n\nhtml_content = '''${htmlCode}''';`);
    // } else {
    //   // If `import json` is not found, optionally add `html_content` at the end
    //   updatedCode += `\n\nhtml_content = '''${htmlCode}''';`;
    // }
  

  return updatedCode;
}

export function addHTMLtoPython(pythonCode, htmlCode){
  let updatedCode = pythonCode.replace(/lxml/g, 'html.parser');
  const soupInitPattern = /soup\s*=\s*BeautifulSoup\(.*html_content.*,.*html\.parser.*\);?/;
  
  if (soupInitPattern.test(updatedCode)) {
    // Insert html_content definition before the soup initialization line
    updatedCode = updatedCode.replace(soupInitPattern, `html_content = '''${htmlCode}''';\n$&`);
  } else {
    // If soup initialization is not found, append html_content at the end
    updatedCode += `\n\nhtml_content = '''${htmlCode}''';`;
  }
  return updatedCode
}

function removeContentAfterHtmlContent(inputString) {
  // Find the starting index of "html_content"
  const startIndex = inputString.indexOf("html_content");
  
  if (startIndex === -1) {
      // "html_content" not found, return the original string
      return inputString;
  }

  // Find the last occurrence of ">"
  let endIndex = inputString.lastIndexOf(">\n");
  if(endIndex === -1){
    endIndex = inputString.lastIndexOf(">''");
  }

  if (endIndex === -1 || endIndex <= startIndex) {
      // No ">" found after "html_content" or ">" is before "html_content", return the string up to "html_content"
      const htmlBracketIndex = inputString.indexOf("HTML]", startIndex);

        if (htmlBracketIndex === -1) {
            // "HTML]" not found after "html_content", return the original string
            const newBracketIndex = inputString.indexOf("here]",startIndex);
            if(newBracketIndex == -1){
              return inputString
            }else{
              return inputString.slice(0, startIndex) + inputString.slice(newBracketIndex + 8);
            }
            
        }

        // Remove content from "html_content" to "HTML]"
        return inputString.slice(0, startIndex) + inputString.slice(htmlBracketIndex + 6);
  }

  // Return the part of the string before "html_content"
  return inputString.slice(0, startIndex) + inputString.slice(endIndex + 6);
}